import React, { useState } from "react";
import axios from "axios";

const TestRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on change
  };

  const validate = () => {
    let valid = true;
    const newErrors = { name: "", phone_number: "", address: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      valid = false;
    }
    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone number is required.";
      valid = false;
    } else if (!/^[0-9]{10}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone number must be 10 digits.";
      valid = false;
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      const response = await axios.post(
        "https://naendapi.naecoaching.com/naecoaching/test-registrations/",
        formData
      );
      setSuccessMessage("Registration successful!");
      setErrorMessage(null);
      setFormData({ name: "", phone_number: "", address: "" });
      
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage(null);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-4">Register for Upcoming Test  <br/> on 27 Feb, 2025</h2>
      <h1 className="text-5xl font-bold text-center text-yellow-500 mb-6">
        Win <span className="text-red-500">₹5000</span>
      </h1>
      {!successMessage && 
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block font-medium mb-1">
            Name (नाम):
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>

        <div>
          <label htmlFor="phone_number" className="block font-medium mb-1">
            Phone Number (फ़ोन नंबर):
          </label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {errors.phone_number && (
            <p className="text-red-500 text-sm mt-1">{errors.phone_number}</p>
          )}
        </div>

        <div>
          <label htmlFor="address" className="block font-medium mb-1">
            Address (पता):
          </label>
          <textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            rows={4}
          ></textarea>
          {errors.address && (
            <p className="text-red-500 text-sm mt-1">{errors.address}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Register (रजिस्टर करें)
        </button>
      </form>
      }
      {successMessage && (
        <div className="mt-6">
          <p className="text-green-500 text-center">{successMessage}</p>
        </div>
      )}
      {errorMessage && (
        <p className="text-red-500 text-center mt-4">{errorMessage}</p>
      )}


        <div className="mt-4 bg-gray-100 p-4 rounded-md">
          <h3 className="font-bold text-lg mb-2">Test Details:</h3>
          <p><strong>Date:</strong> 27th Feb 2025</p>
          <p><strong>Syllabus:</strong> Mathematics (20), Science [ Physics (20), Chemistry (20), Biology (20), and Social Science (20) of Class 10]</p>
          <p><strong>Duration:</strong> 2 Hours</p>
          <p><strong>Test will be Taken:</strong> on Mobile </p>
          <p><strong>Test Location: </strong> N A E Coaching, Railway Gate, Thakurganj</p>

        </div>

    </div>
  );
};

export default TestRegistrationForm;
