import React, { useEffect, useState } from 'react';
import { cn } from '../lib/utils'; 
import Marquee from '../components/magicui/marquee';
import { forwardRef, useRef } from "react";
import { AnimatedBeam } from "../components/magicui/animated-beam";
import WordRotate from "../components/magicui/word-rotate";
import ExploreByExam from '../components/ExploreExam';
import Faculties from '../components/FacultyCard';
// import { Course } from '../components/interfaces';
import CourseList from '../components/CourseListHome';
import { BorderBeam } from "../components/magicui/border-beam";
import CollectiveCourseList from '../components/CollectiveCourses';
import { CollectiveCourse } from '../components/interfaces'
import { useAuth } from '../context/AuthContext'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotificationsPage from '../components/Notification';
import FloatingWhatsAppButton from '../components/FloatingWhatsappButton';
import Language4Ad from '../components/4Language';



const coursesData: string = '';
 
const reviews = [
  {
    name: "Jack",
    username: "@jack",
    body: "I've never seen anything like this before. It's amazing. I love it.",
    img: "https://avatar.vercel.sh/jack",
  },
  {
    name: "Jill",
    username: "@jill",
    body: "I don't know what to say. I'm speechless. This is amazing.",
    img: "https://avatar.vercel.sh/jill",
  },
  {
    name: "John",
    username: "@john",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/john",
  },
];
 
const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative h-40 w-36 cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};
 
function MarqueeDemoVertical() {
  return (
    <div className='text-center'>
    <h1 className='text-xl font-bold py-3'>NAE's Result</h1>
    <div className="relative flex h-[400px] flex-row items-center justify-center overflow-hidden rounded-2xl  bg-background md:shadow-2xl">
      <Marquee pauseOnHover vertical className="[--duration:4s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover vertical className="[--duration:4s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-white dark:from-background"></div>
    </div>

    </div>
  );
}

function MarqueeDemo() {
  return (
    <div className="relative flex  w-full flex-col items-center justify-center overflow-hidden rounded-2xl bg-background md:shadow-2xl">
      <Marquee pauseOnHover className="[--duration:3s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover className="[--duration:3s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
  );
}



const logos = [
  {
    name: "Microsoft",
    img: "https://picsum.photos/id/1/200/300",
  },
  {
    name: "Apple",
    img: "https://picsum.photos/id/2/200/300",
  },
  {
    name: "Google",
    img: "https://picsum.photos/id/3/200/300",
  },
  {
    name: "Facebook",
    img: "https://picsum.photos/id/4/200/300",
  },
  {
    name: "LinkedIn",
    img: "https://picsum.photos/id/5/200/300",
  },
  {
    name: "Twitter",
    img: "https://picsum.photos/id/6/200/300",
  },
];
 
const Marquee3D = () => {
  return (
    <div className='text-center'>
    <h1 className='text-xl font-bold py-3'>NAE's Events</h1>
    <div className="relative flex h-full w-96 flex-col items-center justify-center gap-4 overflow-hidden rounded-2xl bg-background px-20 md:shadow-2xl">
      <div className="flex flex-row gap-4 [perspective:300px]">
        
        <Marquee
          className="h-96 justify-center overflow-hidden [--duration:60s] [--gap:1rem]"
          vertical
          style={{
            transform:
              "translateX(0px) translateY(0px) translateZ(-50px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1.5)",
          }}
        >
          {logos.map((data, idx) => (
            <img
              key={idx}
              src={data.img}
              alt={data.name}
              className="mx-auto h-full w-3/4 cursor-pointer rounded-xl border border-neutral-300 transition-all duration-300 hover:ring-1 hover:ring-neutral-300"
            />
          ))}
        </Marquee>
      </div>
 
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
    </div>
  );
};

const Circle = forwardRef<HTMLDivElement,{ className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

function AnimatedBeamDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex h-[360px] md:h-[500px] w-full items-center justify-center overflow-hidden bg-background p-3 md:p-6"
      ref={containerRef}
    >
      <div className="flex size-full flex-col max-w-lg max-h-[280px] lg:max-h-[400px] items-stretch justify-between gap-4 md:gap-10">
        <div className="flex flex-row items-center justify-between">
          <div className='flex flex-col items-center justify-center'>
          <Circle ref={div1Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
          </svg>
          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Online Lectures</h3>
          </div>
          <div className='flex flex-col items-center justify-center'>
          <Circle ref={div5Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path fillRule="evenodd" d="M2.25 6a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V6Zm18 3H3.75v9a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V9Zm-15-3.75A.75.75 0 0 0 4.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75H5.25Zm1.5.75a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V6Zm3-.75A.75.75 0 0 0 9 6v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75H9.75Z" clipRule="evenodd" />
          </svg>

          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Class Lectures</h3>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
        <div className='flex flex-col items-center justify-center'>
          <Circle ref={div2Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path d="M10.5 18a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
            <path fillRule="evenodd" d="M7.125 1.5A3.375 3.375 0 0 0 3.75 4.875v14.25A3.375 3.375 0 0 0 7.125 22.5h9.75a3.375 3.375 0 0 0 3.375-3.375V4.875A3.375 3.375 0 0 0 16.875 1.5h-9.75ZM6 4.875c0-.621.504-1.125 1.125-1.125h9.75c.621 0 1.125.504 1.125 1.125v14.25c0 .621-.504 1.125-1.125 1.125h-9.75A1.125 1.125 0 0 1 6 19.125V4.875Z" clipRule="evenodd" />
          </svg>

          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Online Notes</h3>
          </div>
          
          <Circle ref={div4Ref} className="size-24">
            <div className='flex flex-col items-center justify-center text-purple-600'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-12">
              <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clipRule="evenodd" />
              <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
            </svg>

          <h1 className='text-[12px] font-bold text-black'>NAE STUD</h1>

          </div>
          

          </Circle>
          <div className='flex flex-col items-center justify-center'>
          <Circle ref={div6Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
            <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
          </svg>

          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Paper Notes</h3>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
        <div className='flex flex-col items-center justify-center'>
          <Circle ref={div3Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path fillRule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z" clipRule="evenodd" />
            <path fillRule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
          </svg>

          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Online Tests</h3>
          </div>
          <div className='flex flex-col items-center justify-center'>
          <Circle ref={div7Ref} className='size-12 md:size-20'>
          <div className='flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 md:size-10">
            <path fillRule="evenodd" d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z" clipRule="evenodd" />
            <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
            <path d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
          </svg>

          </div>
          </Circle>
          <h3 className='text-[12px] font-bold'>Offline Tests</h3>
          </div>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div4Ref}
        
        reverse
      />
    </div>
  );
}


function WordRotateD() {
  return (
    <WordRotate
      className="text-3xl md:text-5xl font-extrabold text-white dark:text-white"
      words={["Science 12", "A r t s 12", "Class 10"]}
    />
  );
}






interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}


const Home: React.FC = () => {
  const { isAuthenticated, studentId, studentClass } = useAuth();
  const [courses, setCourses] = useState<Course[]>([]);
  const [savedCourses, setSavedCourses] = useState<Course[]>([]);
  // const [savedCollectiveCourses, setSavedCollectiveCourses] = useState<CollectiveCourse[]>([]);
  
  const [collectiveCourses, setCollectiveCourses] = useState<CollectiveCourse[]>([]);
  const [yourCourses, setYourCourses] = useState<Course[]>([]);
  const [yourCollectiveCourses, setYourCollectiveCourses] = useState<CollectiveCourse[]>([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCourses = async () => {
      const cachedCourses = localStorage.getItem('courses');
      const coursesExpiry = localStorage.getItem('coursesExpiry');
      const now = new Date();
  
      if (cachedCourses && coursesExpiry && now < new Date(coursesExpiry)) {
        // If cached data exists and is not expired, use it
        setCourses(JSON.parse(cachedCourses).slice(0, 10));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/courses/`);
          setCourses([...response.data].slice(10));

          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem('courses', JSON.stringify(response.data.reverse()));
          localStorage.setItem('coursesExpiry', new Date(now.getTime() + 10 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };

    const fetchCollectiveCourses = async () => {
      const cachedCollectiveCourses = localStorage.getItem('collectiveCourses');
      const collectiveCoursesExpiry = localStorage.getItem('collectiveCoursesExpiry');
      // console.log(cachedCollectiveCourses, collectiveCoursesExpiry)
      const now = new Date();
  
      if (cachedCollectiveCourses && collectiveCoursesExpiry && now < new Date(collectiveCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setCollectiveCourses(JSON.parse(cachedCollectiveCourses).slice(0, 10));
      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/collective-courses/`);          
          setCollectiveCourses([...response.data].slice(-10).reverse());
  
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem('collectiveCourses', JSON.stringify(response.data.reverse()));
          localStorage.setItem('collectiveCoursesExpiry', new Date(now.getTime() + 10 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching collective courses:', error);
        }
      }
    };
  
    fetchCourses();
    fetchCollectiveCourses();

  }, []);

  useEffect(() => {
    

    const fetchYourCourses = async () => {
      const cachedYourCourses = localStorage.getItem(`your${studentId}${studentClass}Courses`);
      const yourCoursesExpiry = localStorage.getItem(`your${studentId}${studentClass}CoursesExpiry`);
      const now = new Date();
  
      if (cachedYourCourses && yourCoursesExpiry && now < new Date(yourCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setYourCourses(JSON.parse(cachedYourCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/courses/by-class/${studentClass}`);
          
          setYourCourses(response.data);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`your${studentId}${studentClass}Courses`, JSON.stringify(response.data));
          localStorage.setItem(`your${studentId}${studentClass}CoursesExpiry`, new Date(now.getTime() + 10 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };

    
    const fetchYourCollectiveCourses = async () => {
      const cachedYourCollectiveCourses = localStorage.getItem(`your${studentId}${studentClass}CollectiveCourses`);
      const yourCollectiveCoursesExpiry = localStorage.getItem(`your${studentId}${studentClass}CollectiveCoursesExpiry`);
      const now = new Date();
  
      if (cachedYourCollectiveCourses && yourCollectiveCoursesExpiry && now < new Date(yourCollectiveCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setYourCollectiveCourses(JSON.parse(cachedYourCollectiveCourses));        

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/collective-courses/by-class/${studentClass}`);

          setYourCollectiveCourses(response.data);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`your${studentId}${studentClass}CollectiveCourses`, JSON.stringify(response.data));
          localStorage.setItem(`your${studentId}${studentClass}CollectiveCoursesExpiry`, new Date(now.getTime() + 10 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching Collectivecourses:', error);
        }
      }
    };
    
    if(studentId && studentClass){
      fetchYourCourses();
      fetchYourCollectiveCourses();
    }

  }, [studentClass]);
  


  const saveCourse = (course: Course) => {
    setSavedCourses((prevCourses) => [...prevCourses, course]);
  };


  return (
    <div className="overflow-hidden">
      <Helmet>
            <title>NAE COACHING | Home</title>
            <meta name="description" content="Bihar board online and offline coaching for Science, Arts and Matric. Offline Coaching in Thakurganj, Kishanganj (Bihar)." />
p
      </Helmet>
      {isAuthenticated ? null : (
        <div className="flex flex-col justify-center items-center">
          <div className="relative flex w-full max-w-5xl p-2 flex-col items-center justify-center overflow-hidden rounded-xl border bg-background md:shadow-xl">
            <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center text-8xl font-bold leading-none text-transparent dark:from-white dark:to-slate-900/10">
              N A E
            </span>
            <BorderBeam size={250} duration={12} delay={9} />
          </div>
          
          <div className="flex flex-col items-center justify-start w-full max-w-5xl p-2 md:p-4 shadow-xl rounded-xl bg-[#52c485]">
            <div className="text-xl md:text-2xl lg:text-3xl font-medium text-center flex flex-col md:flex-row items-center justify-center">
              <span className="text-white text-2xl md:text-4xl font-extrabold mx-3">We are Here</span> for
              <span className="text-white text-2xl md:text-4xl font-extrabold mx-3">Best Results</span> in
              <span className="mx-5"><WordRotateD /></span>
            </div>
            <div className="text-xl md:text-2xl lg:text-3xl font-medium text-center mt-2 md:mt-4">
              <span className="text-white">BSEB Board</span> के लिए <span className="text-white">Expert Guidance!</span>
            </div>
          </div>

          {/* <div className='max-w-4xl m-3 px-4'>
            <div className='w-full p-3 py-5 border-4 border-red-300 shadow-lg shadow-red-800 rounded-2xl text-lg font-bold'>
            <p className='text-lg font-bold text-emerald-700 mb-4'>इन्टॉल और साइन अप कैसे करे </p>
            <div className='flex space-x-4'>
            <Link  to={`/VideoContent/-y1G9Y0GpZU`} className="text-white hover:underline bg-red-500 rounded-xl p-1 mt-4">
            वीडियो  देखें
            </Link>
            <a href='https://www.youtube.com/watch?v=-y1G9Y0GpZU' className="text-white hover:underline bg-red-500 rounded-xl p-1 mt-4">Go to Youtube</a>
            </div>
            </div>
          </div> */}
          <AnimatedBeamDemo />
        </div>
      )}

          <Link to={'/N_A_E_Talent_Test'} className='p-2 rounded-xl shadow-xl flex justify-center items-center'>          
            <img src="https://naendapi.naecoaching.com/media/images/Blue_Creative_Illustration_Learning_Poster_CUhuI5Y.png" />
          </Link>



      {studentId ? (
        <div>
        {yourCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Courses for YOU</h1>
          <p className="text-sm text-center">Explore NAE batches of your interest</p>
          <CourseList courses={yourCourses} />
        </div>
        ) : (<></>)}
        {yourCollectiveCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Collective Courses for YOU</h1>
          <CollectiveCourseList courses={yourCollectiveCourses} />
        </div>
        ) : (<></>)}
        </div>
      ) : (<></>)}

      
      
      <NotificationsPage />

      <div className="container mx-auto p-4">
        <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Explore Collective Courses</h1>
        <CollectiveCourseList courses={collectiveCourses} />
      </div>

      <Language4Ad />

      <div className="container mx-auto p-4 flex flex-col md:flex-row-reverse justify-center items-center">
        <div className="container mx-auto p-4 flex flex-col justify-center items-center">
          <h1 className="text-3xl md:text-5xl text-center font-bold text-gray-900">We do not Promise; </h1>
          <h1 className="text-center text-3xl md:text-5xl font-bold">We have <span className='text-red-500'>Plans!</span></h1>
        </div>
        <img
          src='assets/DanishSir.png'
          className="object-fit w-auto max-h-96"
          alt="Danish Sir"
        />
        
      </div>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Explore by Exam</h1>
        <p className="text-sm text-center">Explore NAE batches by your exam category</p>
        <ExploreByExam />
      </div>


      <div className="container mx-auto p-4">
        <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Explore Our Classes</h1>
        <p className="text-sm text-center">Explore NAE batches of your interest</p>
        <CourseList courses={courses} />
      </div>

      {isAuthenticated ? (
        <>
          
        </>
      ) : (
        <>
      {/* <div className="container mx-auto p-4">
        <Faculties />
      </div> */}
      
      {/* <div className="container mx-auto p-4">
        <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Humse Nahi Hamare Students se Poochhiye...</h1>
        <p className="text-sm text-center">WHAT NAE COACHING PROVIDES FOR A BETTER FUTURE</p>
        <MarqueeDemo />
      </div>

      <div className="container mx-auto p-4">
        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-6 lg:space-x-12 space-y-8 md:space-y-0">
          <MarqueeDemoVertical />
          <Marquee3D />
        </div>
      </div> */}
      </>
      )}
      <FloatingWhatsAppButton
        phoneNumber="+917979061569"  // Replace with your WhatsApp number
        message="Hello! I'd like to ask some queries."  // Optional custom message
        tooltipText="Chat with us on WhatsApp"  // Optional custom tooltip
      />


    </div>
  );
};

export default Home;
