import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import CommentCard from './CommentCard';

import Marquee from '../components/magicui/marquee';
import { cn } from '../lib/utils'; 
const reviews = [
  {
    name: "Jack",
    username: "@jack",
    body: "I've never seen anything like this before. It's amazing. I love it.",
    img: "https://avatar.vercel.sh/jack",
  },
  {
    name: "Jill",
    username: "@jill",
    body: "I don't know what to say. I'm speechless. This is amazing.",
    img: "https://avatar.vercel.sh/jill",
  },
  {
    name: "John",
    username: "@john",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://avatar.vercel.sh/john",
  },
];
 
const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative h-40 w-36 cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

function MarqueeDemo() {
  return (
    <div className="relative flex  w-full flex-col items-center justify-center overflow-hidden rounded-2xl bg-background md:shadow-2xl">
      <Marquee reverse pauseOnHover className="[--duration:3s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee pauseOnHover className="[--duration:3s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
  );
}



interface Comment {
  comment_message: string;
  rating: number;
  created_at: string;
  student: {
    emoji: string;
    name: string;
    student_class: string;
  };
}

const API_URL = process.env.REACT_APP_API_URL;

const CommentList: React.FC = () => {
  const { isAuthenticated, studentId } = useAuth();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated && studentId) {
      const fetchComments = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/users/comments/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          // console.log(response);

          const sortedComments = response.data.sort(
            (a: Comment, b: Comment) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
          setComments(sortedComments);
          // console.log(comments);
          
        } catch (err) {
          setError('Failed to load comments.');
        } finally {
          setLoading(false);
        }
      };

      fetchComments();
    }
  }, [isAuthenticated, studentId]);

  if (loading) {
    return <p>Loading comments...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const firstRow = comments.slice(0, Math.ceil(comments.length / 2));
  const secondRow = comments.slice(Math.ceil(comments.length / 2));

  return (
    <figure className="relative flex flex-col items-center justify-center w-full overflow-hidden p-1">
      {/* <div className="flex gap-6 overflow-x-auto"> */}
      {comments.length === 0 ? (
        <p></p>
      ) : (<>
      <h1 className="text-2xl md:text-3xl text-center font-bold">हमसे नहीं, हमारे STUDENTS से पूछिए ...</h1>
      <Marquee pauseOnHover className="[--duration:5s]">
        {firstRow.map((comment) => (
          <CommentCard
            key={comment.created_at}
            student_name={comment.student.name}
            student_class={comment.student.student_class}
            body={comment.comment_message}
            rating={comment.rating}
            student_emoji={comment.student.emoji}
          />
        ))}
        </Marquee>
      {/* </div> */}
      {/* <div className="flex gap-6 overflow-x-auto mt-4"> */}
      {/* <Marquee reverse pauseOnHover className="[--duration:5s]">
        {comments.map((comment) => (
          <CommentCard
          key={comment.created_at}
          student_name={comment.student.name}
          student_class={comment.student.student_class}
          body={comment.comment_message}
          rating={comment.rating}
          student_emoji={comment.student.emoji}
          />
        ))}
        </Marquee> */}
      {/* </div> */}
      </>)}
    </figure>
  );
};

export default CommentList;
